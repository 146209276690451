.wrapper {
  background: #232324;
  .layout {
    min-height: calc(100vh - 130px);
    background: #232324;
  }
  .content {
    border-radius: 2px;
    position: relative;
    margin-left: 20px;

    .toggleBtn {
      cursor: pointer;
      position: absolute;
      z-index: 11;
      top: 50%;
      margin-top: -33px;
      width: 20px;
      height: 66px;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
  .leftContent {
    width: 260px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    min-height: calc(100vh - 130px);
  }
  .page {
    justify-content: center;
  }
  .leftTitle {
    padding: 16px 16px 8px;
    font-weight: 500;
    line-height: 22px;
    color: var(--mx-text-base-color);
  }
  .card {
    display: flex;
    justify-content: flex-start;
    column-gap: 8px;
    padding: 8px 16px;
    margin-bottom: 4px;
    position: relative;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
    &.active {
      background-color: #3e3e3e;
      &::before {
        position: absolute;
        content: '';
        width: 2px;
        height: 90px;
        background: #00adff;
        left: -1px;
        top: 0;
      }
    }
  }

  .circle {
    width: 12px;
    height: 12px;
    border: 2px solid var(--mx-primary-color);
    border-radius: 6px;
    margin-top: 4px;
  }
  .version {
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 22px;
    margin-bottom: 8px;
  }
  .desc {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    color: var(--mx-text-secondary-color);
    line-height: 20px;
  }
  .line {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 11px;
      left: 4px;
      height: 75px;
      width: 1px;

      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        transparent 50%
      );
      background-size: 1px 8px;
      background-repeat: repeat-y;
    }
  }
}
