.taskInfo {
  margin-bottom: 16px;
  :global {
    .ant-form-item {
      margin: 0 8px 0 0;
    }
  }
}

.testResultCon {
  background: var(--mx-base-bg-color);
  border-radius: 2px;
  border: 1px solid var(--mx-light-border-color);
  padding: 16px 16px 8px;
  margin: 16px 0 0;
  font-size: 14px;

  .resultTitle {
    display: flex;
    align-items: center;

    &::before {
      display: inline-block;
      content: '';
      width: 3px;
      height: 16px;
      background: var(--mx-primary-color);
      margin: 0 16px 0 -16px;
    }
  }

  .index {
    display: flex;
    margin-top: 8px;
    align-items: baseline;
    gap: 4;
  }

  .dot {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 4px;
    background: linear-gradient(90deg, #51a1ff 0%, #51dbff 100%);
  }
}

.testResultColor {
  color: var(--mx-text-secondary-color);
  :global {
    .mx-modal-content {
      color: var(--mx-text-secondary-color);
    }
  }

  .label {
    color: var(--mx-text-base-color);
    margin-right: 4px;
  }
  .successColor {
    color: var(--mx-success-color);
    font-weight: 500;
    margin-left: 4px;
  }

  .warningColor {
    color: var(--mx-warning-color);
    font-weight: 500;
    margin-left: 4px;
  }
}

.gridLayout {
  display: flex;
}

.followImg {
  width: 576px;
}

.overFlowStyle {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.content {
  display: flex;
  height: calc(100vh - 50px);
  align-items: center;
  .left,
  .right {
    width: 56px;
  }
  .icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: calc(100vh - 50px);
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .middle {
    flex: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .fileName {
      width: 100%;
      text-align: center;
      line-height: 54px;
      font-size: 16px;
      font-weight: 500;
      color: var(--mx-text-base-color);
    }
    .fileContent {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      width: calc(100vw - 218px);
      height: 100%;
      // background: rgba(18, 18, 18, 0.7);
    }

    align-items: center;
    height: 100%;

    border: 1px solid;
    border-image: linear-gradient(360deg, rgba(0, 173, 255, 0), rgba(0, 173, 255, 1), rgba(0, 173, 255, 0.01)) 1 1;
  }
}
