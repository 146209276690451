.taskInfo {
  margin-bottom: 16px;
  :global {
    .ant-form-item {
      margin: 0 8px 0 0;
    }
  }
}

.testResultCon {
  background: var(--mx-base-bg-color);
  border-radius: 2px;
  border: 1px solid var(--mx-light-border-color);
  padding: 16px 16px 8px;
  margin: 16px 0 0;
  font-size: 14px;

  .resultTitle {
    display: flex;
    align-items: center;

    &::before {
      display: inline-block;
      content: '';
      width: 3px;
      height: 16px;
      background: var(--mx-primary-color);
      margin: 0 16px 0 -16px;
    }
  }

  .index {
    display: flex;
    align-items: center;
  }

  .dot {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 4px;
    background: linear-gradient(90deg, #51a1ff 0%, #51dbff 100%);
  }
}

.testResultColor {
  color: var(--mx-text-secondary-color);
  :global {
    .mx-modal-content {
      color: var(--mx-text-secondary-color);
    }
  }

  .label {
    color: var(--mx-text-base-color);
  }
  .successColor {
    color: var(--mx-success-color);
  }

  .warningColor {
    color: var(--mx-warning-color);
  }
}

.caseTable {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.overFlowStyle {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
