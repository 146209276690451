.required {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ac222a;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.rangeIcon {
  display: inline-block;
  line-height: 30px;
  height: 30px;
}