.mx-upload {
  display: flex;
  flex-direction: column;
  position: relative;

  .ant-upload-wrapper:not(.ant-upload-picture-card-wrapper) {
    .ant-upload.ant-upload-select {
      margin-bottom: 30px;
    }
  }

  .mx-upload-tip {
    font-size: var(--mx-font-size-normal);
    line-height: var(--mx-line-height-normal);
    color: var(--mx-upload-tip-color);
    padding-top: 8px;
  }

  .mx-error-msg {
    font-size: var(--mx-font-size-normal);
    line-height: var(--mx-line-height-normal);
    color: var(--mx-error-color);
    margin-top: 8px;
    p {
      margin-bottom: 8px;
    }
  }

  .ant-upload-wrapper:not(.ant-upload-picture-card-wrapper) + .mx-upload-tip {
    position: absolute;
    top: 32px;
  }

  .ant-upload-picture-card-wrapper {
    .ant-upload.ant-upload-select {
      border-color: var(--mx-upload-select-border-color);

      &.ant-upload-disabled {
        background-color: var(--mx-upload-disabled-bg-color);

        .anticon,
        .anticon + div {
          color: var(--mx-upload-disabled-color);
        }
      }

      .ant-upload {
        flex-direction: column;
      }

      .anticon {
        font-size: 16px;
        color: var(--mx-upload-color);
      }

      .anticon + div {
        margin-top: 10px;
        color: var(--mx-upload-select-disabled-anticon-div-color);
        font-size: var(--mx-font-size-normal);
        line-height: var(--mx-line-height-normal);
      }
    }
    .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
      margin-block: 0 16px;
      margin-inline: 0 16px;
    }
    .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
      border-color: var(--mx-upload-picture-item-border-color);
      &:hover {
        background-color: var(--mx-upload-picture-item-hover-bg-color);
      }
    }

    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
      border-color: var(--mx-upload-select-hover-border-color);
    }
  }

  .ant-upload-drag {
    border-color: var(--mx-upload-select-border-color);
    &:not(.ant-upload-disabled):hover {
      border-color: var(--mx-upload-select-hover-border-color);
    }
  }
  .ant-upload-drag.ant-upload {
    padding: 38px 0;

    .mx-upload-drag-icon {
      .anticon {
        font-size: 48px;
        color: var(--mx-upload-icon-active-color);
      }
      margin-bottom: 16px;
    }

    .mx-upload-text {
      font-size: var(--mx-font-size-H2);
      line-height: var(--mx-line-height-H2);
      margin-bottom: 4px;
      color: var(--mx-upload-color);
    }

    .mx-upload-hint {
      font-size: var(--mx-font-size-normal);
      line-height: var(--mx-line-height-normal);
      color: var(--mx-upload-color);
      margin: 0;
    }

    &.ant-upload-disabled {
      background-color: var(--mx-upload-disabled-bg-color);

      .mx-upload-drag-icon .anticon,
      .mx-upload-text,
      .mx-upload-hint {
        color: var(--mx-upload-disabled-color);
      }
    }
  }
  .ant-upload-list-item {
    .ant-upload-icon .anticon {
      color: var(--mx-upload-color);
    }

    .ant-upload-icon .anticon.anticon-loading {
      color: var(--mx-upload-icon-active-color);
    }

    a {
      color: var(--mx-upload-color);
    }

    .ant-progress .ant-progress-inner {
      background-color: var(--mx-upload-progress-bg-color);
    }
    .ant-progress .ant-progress-bg {
      background-color: var(--mx-upload-progress-active-bg-color);
    }

    &.ant-upload-list-item-error {
      .ant-upload-icon .anticon,
      .ant-upload-list-item-name {
        color: var(--mx-error-color);
      }
    }

    .ant-upload-list-item-actions .ant-btn {
      background: none;
    }

    .ant-upload-list-item-actions .anticon-eye,
    .ant-upload-list-item-actions .anticon-delete {
      color: var(--mx-upload-icon-color) !important;
      &:hover {
        color: var(--mx-upload-color) !important;
      }
    }
  }

  .mx-upload-error-item {
    color: var(--mx-error-color);
    font-size: 0;

    .item-file-name {
      font-size: 14px;
      line-height: 22px;
      .anticon {
        font-size: 16px;
        margin-right: 4px;
      }
    }
    .ant-progress {
      line-height: 2px;
      margin-top: 4px;
      margin-inline-start: 20px;
      width: calc(100% - 20px);
    }
  }
}

.mx-upload-preview {
  .ant-modal-header {
    border-bottom: none;
  }
}
